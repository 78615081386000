import React from "react";
import cx from "classnames";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import ProfileComponent from "views/Pages/Profile";
import vehicleComponent from "views/Pages/editvehicle";
import UpdatePinComponent from "views/Pages/EditPins";
import addvehicleComponent from "views/Pages/addvehicle";
import addsubscriptionComponent from "views/Forms/AddSubscription";
import editsubscriptionComponent from "views/Forms/EditSubscription";
import addorganizationComponent from "views/Pages/Addorganization";
import addlocationComponent from "views/Pages/Addlocation";
import addFeeComponent from "views/Pages/AddFee";
import editFeeComponent from "views/Pages/EditFee";
import editLocationComponent from "views/Pages/EditLocation";
import AddPinComponent from "views/Pages/AddPins";
import AddCarouselComponent from "views/Forms/AddCarousel.js";
import EditCarouselComponent from "views/Forms/EditCarousel.js";
import DiscountParkingSession from "views/Forms/DiscountParkingSession.js";
import AddDiscount from "views/Forms/AddDiscount";
import EditDiscount from "views/Forms/EditDiscount";
// import updateUserComponent from "views/Forms/EditUser.js";
import EditTicket from "views/Forms/EditTicket.js";
import AddTicket from "views/Forms/AddTicket.js";
import EditUser from "views/Pages/EditUser";
import AddUser from "views/Pages/AddUser";
import MainDashboard from "views/Dashboard/MainDashboard";
import SuperAdminDashboard from "views/Dashboard/SuperAdminDashboard";
import ParkingSessions from "views/Tables/ParkingSessions";
import BookingHistory from "views/Tables/BookingHistory";
import CarouselQR from "views/Pages/CarouselQR";
import Notifications from "views/Pages/Notifications";
import AddConfiguration from "views/Pages/AddConfiguration";
import EditConfiguration from "views/Pages/EditConfiguration";
import OnboardWizard from "views/Forms/OnboardWizard.js";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [color] = React.useState("orange");
  const [bgColor] = React.useState("white");
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Stak Mobility Admin";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Stak Admin"}
        logo={require("assets/img/logo.svg")}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.map}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Route exact path="/" component={MainDashboard} />
              <Route
                exact
                path="/organizations"
                component={SuperAdminDashboard}
              />
              <Route
                exact
                path="/subscription/add"
                component={addsubscriptionComponent}
              />
              <Route
                exact
                path="/subscription/edit/:id"
                component={editsubscriptionComponent}
              />
              <Route
                exact
                path="/vehicles/edit/:id"
                component={vehicleComponent}
              />
              {/* <Route
                exact
                path="/users/edit/:id"
                component={updateUserComponent}
              /> */}
              <Route exact path="/users/edit/:id" component={EditUser} />
              <Route exact path="/users/add" component={AddUser} />
              <Route
                exact
                path="/vehicles/add"
                component={addvehicleComponent}
              />
              <Route
                exact
                path="/organization/add"
                component={addorganizationComponent}
              />
              <Route
                exact
                path="/location/add"
                component={addlocationComponent}
              />
              <Route
                exact
                path="/fee-structure/add"
                component={addFeeComponent}
              />
              <Route
                exact
                path="/fee-structure/edit/:id"
                component={editFeeComponent}
              />
              <Route
                exact
                path="/location/edit/:id"
                component={editLocationComponent}
              />
              <Route exact path="/pins/add" component={AddPinComponent} />
              <Route
                exact
                path="/pins/edit/:id"
                component={UpdatePinComponent}
              />
              <Route path="/admin/profile" component={ProfileComponent} />
              <Route
                path="/admin/add-carousel"
                component={AddCarouselComponent}
              />
              <Route
                exact
                path="/carousel/edit/:id"
                component={EditCarouselComponent}
              />
              <Route exact path="/carousel/:token" component={CarouselQR} />
              <Route
                exact
                path="/admin/notifications"
                component={Notifications}
              />
              <Route
                exact
                path="/parking-sessions"
                component={ParkingSessions}
              />
              <Route exact path="/ticket/edit/:id" component={EditTicket} />
              <Route exact path="/ticket/create" component={AddTicket} />
              <Route
                exact
                path="/parking-sessions/:id/discount"
                component={DiscountParkingSession}
              />
              <Route
                exact
                path="/user-subscription-booking/:id/history"
                component={BookingHistory}
              />
              <Route exact path="/discount/add" component={AddDiscount} />
              <Route exact path="/discount/edit/:id" component={EditDiscount} />

              <Route
                exact
                path="/configuration/create"
                component={AddConfiguration}
              />
              <Route
                exact
                path="/configuration/edit/:id"
                component={EditConfiguration}
              />

              <Route exact path="/admin" component={OnboardWizard} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
