/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ConfigurationService from "services/ConfigurationService";
import SettingsIcon from "@material-ui/icons/Settings";
import LocationService from "services/LocationService";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

const configurationTypesList = require("../../assets/json/ConfigurationTypes");
function FeeForm({ id = null }) {
  var numberRex = new RegExp("^[0-9]+$");
  const [isEdit] = React.useState(id ? true : false);
  const [label] = React.useState(
    id ? "Update Configuration" : "Add Configuration"
  );

  const [configuration, setConfiguration] = React.useState({
    amount: null,
    name: "",
    type: "",
  });
  const api = useAPI();
  const history = useHistory();

  const [errors, setErrors] = React.useState({
    amount: "",
    name: "",
  });

  const fetchConfiguration = async () => {
    await ConfigurationService.getConfiguration(id).then((res) => {
      console.log("RES", res);
      setConfiguration(res);
    });
  };

  React.useEffect(() => {
    ConfigurationService.init(api);
    if (id) fetchConfiguration();
  }, [api]);

  const handleChange = (prop, val) => {
    console.log(configuration);
    setConfiguration({ ...configuration, [prop]: val });
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ["type", "name"].forEach((field) => {
      if (configuration[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    if (
      configuration.type === "Additional Fees" &&
      (configuration.amount === "" || !configuration.amount)
    ) {
      newErrors.amount = "error";
      error = 1;
    } else if (configuration.type === "Canned Messages") {
      configuration.amount = null;
    }

    console.log("Error", newErrors);
    setErrors({ ...newErrors });

    if (error) return;

    if (id) await ConfigurationService.updateConfiguration(configuration);
    else await ConfigurationService.addConfiguration(configuration);
    history.push("/admin/configuration");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SettingsIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{label}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.selectFormControl}
                error={errors.type === "error"}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose Configuration Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={configuration.type}
                  onChange={(e) => {
                    handleChange("type", e.target.value);
                  }}
                  // error={errors.type === "error"}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                  label="Choose Configuration Type"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Configuration Type
                  </MenuItem>

                  {configurationTypesList.map((item, index) => (
                    <MenuItem
                      value={`${item.name}`}
                      key={`${item.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${item.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.name === "error"}
                margin="dense"
                id="name"
                label="Name *"
                variant="outlined"
                type="text"
                fullWidth
                value={configuration.name}
                onChange={(event) => {
                  setConfiguration({
                    ...configuration,
                    name: event.target.value,
                  });
                }}
              />
              {configuration.type === "Additional Fees" && (
                <TextField
                  error={errors.amount === "error"}
                  margin="dense"
                  id="amount"
                  label="Amount *"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={configuration.amount}
                  onChange={(event) => {
                    setConfiguration({
                      ...configuration,
                      amount: event.target.value,
                    });
                  }}
                />
              )}

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button
                color="rose"
                onClick={registerClick}
                className={classes.registerButton}
              >
                {label}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

FeeForm.defaultProps = {
  id: null,
};

export default FeeForm;
